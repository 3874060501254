.leaflet-container {
  height: 100%;
  width: 100%;
}

#map {
  width:960px;
  height:500px;
}
#legend {
  position: absolute;
  top: 3px;
  left: 780px;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
  z-index: 100;
  font-size: 1em;
  font-family: sans-serif;
  width: 165px;
  background: rgba(255,255,255,0.6);
}
.legendheading {
  position: relative;
  height: 25px;
  padding: 5px 2px 0px 2px;
  font-size: larger;
  font-weight: bold;
}
.legenditem {
  padding: 2px;
  margin-bottom: 2px;
}

/*Marker clusters*/
.marker-cluster-pie g.arc{
  fill-opacity: 0.5;
}
.marker-cluster-pie-label {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*Markers*/
.marker {
  width: 22px!important;
  height: 22px!important;
  border-width: 2px!important;
  border-radius:50%!important;
  margin-top: -13px!important;
  margin-left: -13px!important;
  border-style: solid;
  fill: #CCC;
  stroke: #444;
  background: #CCC;
  border-color: #444;
}
.marker div{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

/*marker categories*/

.category-online{
  fill: #BFB;
  stroke: #070;
  background: #BFB;
  border-color: #070;
  opacity: 0.7;
}
.category-away{
  fill: #FF3;
  stroke: #D80;
  background: #FF3;
  border-color: #D80;
  opacity: 0.7;
}
.category-offline{
  fill: #F88;
  stroke: #800;
  background: #F88;
  border-color: #800;
  opacity: 0.7;
}

/*marker icons*/
.icon-monitor{
  background-image: url('monitor.png');
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 1;
}
/*Popup*/
.map-popup span.heading {
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
.map-popup span.attribute {
  display: block;
}
.map-popup span.label {
  font-weight: bold;
}