@import url('~react-leaflet-markercluster/dist/styles.min.css');
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg) scaleX(-1);
    -o-transform: rotate(0deg) scaleX(-1);
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    -webkit-transform: rotate(360deg) scaleX(-1);
    -o-transform: rotate(360deg) scaleX(-1);
    transform: rotate(360deg) scaleX(-1);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg) scaleX(-1);
    -moz-transform: rotate(0deg) scaleX(-1);
    -webkit-transform: rotate(0deg) scaleX(-1);
    -o-transform: rotate(0deg) scaleX(-1);
    transform: rotate(0deg) scaleX(-1);
  }
  to {
    -ms-transform: rotate(360deg) scaleX(-1);
    -moz-transform: rotate(360deg) scaleX(-1);
    -webkit-transform: rotate(360deg) scaleX(-1);
    -o-transform: rotate(360deg) scaleX(-1);
    transform: rotate(360deg) scaleX(-1);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.rotated {
  transform: scaleX(-1);
}