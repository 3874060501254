.masonry-item-wrapper {
  max-width: 225px;
  margin: 10px;
  /* border-radius: 10px; */
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.masonry-item-wrapper img:hover {
  opacity: 0.8;
}

/* .masonry-item-name {
  position: absolute;
  bottom: 10px;
  left: 5px;
  background-color: rgba(199, 199, 199, 0.7);
  text-align: center;
  padding: 3px;
} */

.my-gallery-class {
  overflow-y: auto;
}
.masonry-item-checkbox-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
  z-index: 999;
}
.masonry-item-transcoding-icon-wrapper {
  padding: 5px;
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 999;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.masonry-item-wrapper:hover .masonry-item-checkbox-wrapper {
  display: block;
}

.fixed-draggable-item{
  transform: none !important;
}
.masonry-item-additional-info {
  font-size: 12px;
  color: gray;
  cursor: default;
}
@media (max-width: 600px) {
  .masonry-item-wrapper {
    max-width: 155px;    
  }
  .masonry-item-additional-info {
    display: none;
  }
}