#ani{
    animation:spin 1000ms  linear infinite;
    transform-origin: 252px 184px;
}

@keyframes spin {
    100%{
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }

}
#noRot{
    transform-origin: 252px 184px;
    transform:rotate(-90deg);
}
.uploadInfoWrapper {
    animation: crescendo 0.5s alternate ease-out;
}
  
@keyframes crescendo {
    0%   {transform: scale(0);}
    100% {transform: scale(1);}
}

.uploadInfoWrapperFinished {
    animation-duration: 1.5s;
    animation-name: bounce-5;
    animation-timing-function: ease;
}
@keyframes bounce-5 {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.9) translateY(0); }
    30%  { transform: scale(.9,1.1) translateY(-25px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-7px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}